import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { ChevronRight, Close } from "@material-ui/icons";
import sideMenuStyles from "./SideMenuStyle";
import clsx from "clsx";
// how this sidemenu works ?
// : It only renders the object when there's "showInLibrary" in the object
// : check for "subSection" if not there , render the "showInlibrary" as a section.
// : if "subSection" is true then it renders actions as subSections
// : if "showInLibrary" and "subSection" are true , it checks for title if == 'buttons' then it renders "showInlibrary" as a subsection
function SideMenu(props) {
  const classes = sideMenuStyles();

  const sectionOnClick = (section) => {
    props.onClose();
    props.goToSection(section);
  };
  const actionOnClick = (action) => {
    props.onClose();
    props.goToAction(action)
  }
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={classes.drawerHeader}>
        {/* <IconButton onClick={props.onClose} color="inherit">
          <Close />
        </IconButton> */}
        Video Library
      </div>
      <Divider />
      <List>
        {props.sections &&
          props.sections.map(
            (section, index) =>
              (section.showInLibrary || section.subSection) && (
                <div key={section.start}>
                  {!section.subSection && <ListItem
                    button
                    divider
                    key={index}
                    onClick={() => sectionOnClick(section)}
                    className={classes.listItem}
                  >
                    <span> - </span>
                    <div
                      className={clsx([
                        classes.listItemText,
                        section.active && classes.listItemTextActive,
                      ])}
                      dangerouslySetInnerHTML={{ __html: section.showInLibrary }}
                    />


                  </ListItem>}
                  {
                    (section.subSection && section.title !== 'buttons') && <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {section.actions.map((action) => {
                        return (<ListItem
                          style={{ paddingLeft: '40px' }}
                          button
                          divider
                          key={index}
                          onClick={() => actionOnClick(action)}
                          className={classes.listItem}
                        >{action.html}</ListItem>)
                      })}
                    </div>
                  }

                  {section.title == 'buttons' && <ListItem
                    style={{ paddingLeft: '40px' }}
                    button
                    divider
                    key={index}
                    onClick={() => sectionOnClick(section)}
                    className={classes.listItem}
                  >{section.showInLibrary}</ListItem>}
                </div>
              )

          )}
      </List>
    </Drawer >
  );
}

export default SideMenu;
