import "../App.css";
import { createRef, useEffect, useState } from "react";
import {
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay10,
  VolumeOff,
  VolumeUp,
} from "@material-ui/icons";
import data from "../data.json";
import TopBar from "../TopBar/TopBar";

import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import answerGIF from "../assets/novo/answer.gif";
import callingGIF from "../assets/novo/calling.gif";
import novoVideo from "../assets/novo/videos/migraine.mp4";
import { db } from "../firebase-config";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import logo from "../assets/logo.png";
import Image from "../ImageComponent/Image";
import SmallVideo from "../smallVideo/SmallVideo";
import SideMenu from "../SideMenu/SideMenu";
// import SmallVideo from "../smallVideo/SmallVideo";

function VideoComponent(props) {
  const videoRef = createRef();
  const [previousSection, setPreviousSection] = useState();
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState();
  const [showPicture, setShowPicture] = useState(false);
  const [showSmallVideo, setShowSmallVideo] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [popup, setPopup] = useState();
  const [calling, setCalling] = useState();
  const [sections, setSections] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [played, setPlayed] = useState({});
  const [playedPictures, setPlayedPictures] = useState([]);
  const [interaction, setInteraction] = useState();
  const handleFS = useFullScreenHandle();

  const handleDrawerOpen = () => {
    if (popup) return;
    setDrawerOpen(!drawerOpen);
  };
  const drawerOnClose = () => {

    setDrawerOpen(false);
  };
  useEffect(() => {
    setVideoData(props.data);
  }, [props]);

  const init = () => {
    let duration = 0;
    let lastInteractionTime = 0;
    if (videoRef && videoRef.current) {
      duration = videoRef.current.duration;
    }
    let sections = data.interactions.map((interaction) => {
      let time = interaction.time || duration;
      interaction.duration = time - lastInteractionTime;
      lastInteractionTime = interaction.time;
      interaction.disabled = true;
      return interaction;
    });
    setVideoData({ ...videoData, duration, sections });
  };

  const toggleSound = (muted = false) => {
    const x = videoRef.current;
    x.muted = !x.muted;
    setVideoData({ ...videoData, muted: x.muted });
  };

  const toggleVideo = async () => {
    if (popup) return;
    const x = videoRef.current;
    if (x.paused) {
      x.play();
    } else {
      x.pause();
    }
    if (props.member) {
      const emailDoc = await db.collection("migraine").doc(props.member).get();
      let emailData = emailDoc.data();
      if (!emailData.actions) emailData.actions = [];
      emailData.actions = [
        ...emailData.actions,
        {
          action: x.paused ? "Pause" : "Play",
          time: Date.now(),
        },
      ];
      db.collection("migraine").doc(props.member).set(emailData);
    }
  };

  const seekVideo = (forward = true) => {
    try {
      if (videoRef && videoRef.current) {
        let time = videoRef.current.currentTime;
        let currentSection = videoData.interactions.find(
          (interaction) => interaction.active === true
        );
        if (forward) {
          time += 10;
        } else {
          time -= 10;
        }
        if (time < currentSection.start) {
          time = currentSection.start;
        }
        if (currentSection.time && time >= currentSection.time) {
          time = currentSection.time - 1;
        }
        videoRef.current.currentTime = time;
      }
    } catch (e) {
      console.log("seekVideo Error", e);
    }
  };

  const goToPreviousOptions = () => {
    try {
      if (videoRef && videoRef.current && previousSection) {
        const videoElm = videoRef.current;
        videoElm.currentTime = previousSection.time;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const replaySection = (section) => {
    setPreviousSection(null);
    setSections();
    if (videoRef && videoRef.current) {
      const videoElm = videoRef.current;
      videoElm.currentTime = section.start;
      videoElm.play();
      let selectedSection = videoData.interactions.find(
        (interaction) => interaction.title === section.title
      );
      if (selectedSection && selectedSection.sections) {
        const index = selectedSection.actions
          .map((action) => action.html)
          .indexOf(section.title);
        console.log(played);
        if (played[section.nextSection] === undefined) {
          played[section.nextSection] = [];
        }
        if (index !== -1 && !played[section.nextSection].includes(index + 1)) {
          let tmp = {
            ...played,
            [section.nextSection]: [...played[section.nextSection], index + 1],
          };
          setPlayed(tmp);
        }
      }
    }
  };

  const timeChange = () => {
    const x = videoRef.current;
    setTime(x.currentTime);

    // if (x.currentTime >= 0 && x.currentTime <= 5) { // in development skipping tricks 
    //   x.currentTime = 981 // skip to this time 
    // }

    if (videoData) {
      if (data.interactions) {
        const interactions = data.interactions.map((interaction) => {
          interaction.active =
            x.currentTime >= interaction.start &&
            (x.currentTime <= interaction.time || !interaction.time);
          if (interaction.active) {
            interaction.disabled = false;
            setInteraction(interaction);
          }
          if (interaction.nextSection === 907) {
            if (
              interaction.active &&
              played[interaction.nextSection] &&
              played[interaction.nextSection].length >=
              interaction.actions.length &&
              Math.round(x.currentTime) <= Math.round(interaction.time) - 2
            ) {
              setShowPicture({ show: false });
              continueVideo({ time: interaction.nextSection });
              setPopup();

              setPlayedPictures([]);
            }
          }
          if (Math.round(x.currentTime) === Math.round(interaction.time)) {
            if (interaction.actions.length > 0) {
              if (interaction.title === "calling") {
                setCalling(interaction);
                x.pause();
              } else if (interaction.sections) {
                setPopup(interaction);
                drawerOnClose()
                let currentPlayed = played[interaction.nextSection];
                if (currentPlayed === undefined) currentPlayed = [];
                console.log(playedPictures.length, interaction.actions.length);
                if (playedPictures.length >= interaction.actions.length) {
                  console.log("finished", interaction);
                  setPlayedPictures([]);
                  x.muted = false;
                  continueVideo({ time: interaction.nextSection }); // im setting time in an object because in this function it will access the time like this "t.time"
                  console.log("we are finished : ", interaction);
                  setPopup();
                } else if (currentPlayed.length >= interaction.actions.length) {
                  console.log("finished", interaction);
                  setShowPicture({ show: false });
                  continueVideo({ time: interaction.nextSection }); // im setting time in an object because in this function it will access the time like this "t.time"
                  console.log("we are finished : ", interaction);
                  setPopup();
                  x.muted = false;
                  setPlayedPictures([]);
                } else {
                  setSections(true);
                  x.pause();
                }
              } else {
                setPopup(interaction);
                drawerOnClose()

                x.pause();
              }
            }
          }
          return interaction;
        });
        setVideoData({ ...videoData, interactions });
      }
    }
  };

  const playSection = (section_id, id) => {
    console.log("playSection", section_id, id);
    let currentPlayed = played[section_id];
    if (currentPlayed === undefined) currentPlayed = [];
    if (currentPlayed.indexOf(id) !== -1) return;
    console.log(played);
    let currentSection = videoData.interactions.find(
      (interaction) => interaction.active === true
    );
    let tmp = {
      ...played,
      [section_id]: [...currentPlayed, id],
    };
    setPlayed(tmp);
    try {
      console.log(currentSection);
      const action = currentSection.actions[parseInt(id) - 1];
      continueVideo(action);
    } catch (error) {
      console.log(error);
      console.log(currentSection);
    }
  };
  const playSmallVideo = () => {
    setShowSmallVideo(true)
  }
  const closeSmallVideo = () => {
    continueVideo({ time: interaction.nextSection }).then(() => {
      setShowSmallVideo(false)
    });

  }
  const playPicture = (section_id, id, nextPicture, nextButtonStyle) => {
    if (playedPictures.indexOf(id) !== -1) return;
    let tmp = [...playedPictures, id];
    setPlayedPictures(tmp);
    setShowPicture({ show: true, picture: id, nextPicture: nextPicture, nextButtonStyle: nextButtonStyle });

    let currentPlayed = played[section_id];
    if (currentPlayed === undefined) currentPlayed = [];
    if (currentPlayed.indexOf(id) !== -1) return;
    tmp = {
      ...played,
      [section_id]: [...currentPlayed, id],
    };
    setPlayed(tmp);
  };

  const continueVideo = async (t) => {
    try {
      let currentSection = videoData.interactions.find(
        (interaction) => interaction.active === true
      );
      if (currentSection) setPreviousSection(currentSection);
      const x = videoRef.current;
      x.currentTime = parseInt(t.time);
      if (Math.round(x.currentTime) === Math.round(parseInt(t.time))) {
        x.currentTime += 1;
      }
      x.play();
      setPopup();
      setCalling();
      setSections();
      if (props.member) {
        const emailDoc = await db
          .collection("migraine")
          .doc(props.member)
          .get();
        let emailData = emailDoc.data();
        if (!emailData.actions) emailData.actions = [];
        emailData.actions = [
          ...emailData.actions,
          {
            action: "Select",
            section: t,
            time: Date.now(),
          },
        ];
        db.collection("migraine").doc(props.member).set(emailData);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <FullScreen handle={handleFS}>
      {handleFS.active && (
        <>
          <div
            className="fullscreen-bg"
            style={{
              backgroundImage: `url(${videoData.background_image})`,
            }}
          />
        </>
      )}
      {videoData && (
        <div handle={handleFS} className="backgroundVideo">
          <Image
            onClose={() => {
              const x = videoRef.current;
              x.currentTime -= 1;
              x.muted = true;
              x.play();
              setShowPicture({ show: false });
              // toggleSound()
            }}
            nextStyle={showPicture.nextButtonStyle}
            nextPicture={showPicture.nextPicture}
            image={showPicture.picture}
            display={showPicture.show}
          />
          {showSmallVideo && <SmallVideo onFinish={() => closeSmallVideo()} show={showSmallVideo} />}
          <CssBaseline />
          <ClickAwayListener onClickAway={drawerOnClose}>
            <div>
              <TopBar open={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
              <SideMenu
                goToAction={(action) => continueVideo({ time: action.time })}
                sections={videoData.sections}
                goToSection={replaySection}
                open={drawerOpen}
                onClose={drawerOnClose}
              />
            </div>
          </ClickAwayListener>
          {!handleFS.active && <h1>{videoData.title}</h1>}
          <video
            ref={videoRef}
            id="video1"
            onLoadedData={init}
            poster={videoData.poster}
            onTimeUpdate={timeChange}
            onPlaying={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onVolumeChange={() =>
              setVideoData({ ...videoData, muted: videoRef.current.muted })
            }
            playsInline
          >
            <source id="mp4" src={novoVideo} type="video/mp4" />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
          {calling && (
            <Box
              sx={{
                background: `url(${calling.actions.length === 2 ? callingGIF : answerGIF
                  })`,
              }}
              className="calling-container"
            >
              {calling.actions.map((t, i) => (
                <button onClick={() => continueVideo(t)} key={i}></button>
              ))}
            </Box>
          )}
          {/* {time} */}

          <div className="content toggleOnHover">
            <Box p={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => seekVideo(false)}
                    >
                      <Replay10 />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleVideo}
                    >
                      {(playing && <Pause />) || <PlayArrow />}
                    </Button>
                    {/* {previousSection && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToPreviousOptions}
                      >
                        Back to options
                      </Button>
                    )} */}
                  </ButtonGroup>
                </Grid>
                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleFS.active ? handleFS.exit() : handleFS.enter()
                      }
                    >
                      {(handleFS.active && <FullscreenExit />) || (
                        <Fullscreen />
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleSound}
                    >
                      {(videoData.muted && <VolumeOff />) || <VolumeUp />}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      )}

      {popup && (
        <div className="lightbox">
          {popup.question && (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div className="button longbtns">
                <p>{popup.question}</p>
              </div>
            </div>
          )}
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: popup.question ? "row" : "column",
              width: "100%",
              flex: 1,
              justifyContent: "space-evenly",
            }}
          >
            {popup.actions.map((t, i) => {
              let seen = false;
              if (popup.sections && played[popup.nextSection]) {
                console.log("check", i);
                seen = played[popup.nextSection].includes(i + 1);
              }
              return (
                <div
                  key={i}
                  style={t.style ? t.style : {}}
                  className={`button longbtns ${t.style && !seen ? "animate-flicker" : ""
                    } ${seen ? "disabled" : ""}`}
                  onClick={() => {
                    if (t.smallVideo) {
                      playSmallVideo()
                    }
                    else if (t.picture) {
                      console.log(i + 1);
                      playPicture(popup.nextSection, t.picture, t.nextPicture, t.nextButtonStyle);
                    } else if (popup.sections) {
                      playSection(popup.nextSection, i + 1);
                    }
                    else {
                      continueVideo(t);
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: t.style ? "" : t.html }}
                />
              );
            })}

            <div onClick={() => {
              continueVideo({ time: popup.nextSection })
            }} className="skipButton">Skip</div>
          </div>

        </div>
      )}
    </FullScreen>
  );
}

export default VideoComponent;
