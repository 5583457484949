import classes from './image-styles.module.css'
import img1 from '../assets/images/1.jpg'
import img2 from '../assets/images/2.jpg'
import img3 from '../assets/images/3.jpg'
import img4 from '../assets/images/4.jpg'
import img5 from '../assets/images/5.png'
import img6 from '../assets/images/6.jpg'
import img7 from '../assets/images/7.jpg'
import img8 from '../assets/images/8.jpg'
import img9 from '../assets/images/9.png' // this is png
import img10 from '../assets/images/10.jpg'
import img11 from '../assets/images/11.jpg'
import img12 from '../assets/images/12.png'
import img13 from '../assets/images/13.jpg'
import { useEffect, useState } from 'react'

let imgArr = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13]
const Image = (props) => {
    const [clickedNext, setClickedNext] = useState(false)

    const [currentImg, setCurrentImg] = useState(clickedNext ? props.nextPicture : props.image)
    const [bg, setBg] = useState(imgArr[currentImg - 1])
    // let bg = imgArr[currentImg - 1]
    let nextStyle = props.nextButtonStyle;
    console.log(nextStyle);
    useEffect(() => {
        console.log(props);
        console.log(props);
        setCurrentImg(clickedNext ? props.nextPicture : props.image)
        let temp = imgArr[currentImg - 1]
        setBg(temp);
    }, [bg, currentImg, props.image, clickedNext])
    return (
        <div className={classes.image}
            style={{
                display: props.display ? 'flex' : 'none',
                backgroundImage: "url(" + bg + ")"
            }}>

            {!clickedNext && props.nextPicture && <div
                style={{ position: 'absolute', bottom: '5vw', fontSize: '1.5vw', right: '6vw', width: '6vw', height: '3vw', backgroundColor: '#98024d59', borderRadius: '10vw', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}
                onClick={() => {
                    setClickedNext(true)
                }}>Next</div>}

            <div onClick={() => {
                props.onClose()
                setClickedNext(false)
            }} className={classes.close}>X</div>
        </div>)
}
export default Image;