import "../App.css";
import { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import logo from "../assets/logo.png";
import { ArrowForward } from "@material-ui/icons";
import VideoComponent from "../VideoComponent/VideoComponent";
import { Helmet } from "react-helmet";
import data from "../data.json";
// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOB6Y6H3z3c-EXz66hEfiV6wbcUBL5z-Y",
  authDomain: "c-and-d-iv.firebaseapp.com",
  projectId: "c-and-d-iv",
  storageBucket: "c-and-d-iv.appspot.com",
  messagingSenderId: "993254260935",
  appId: "1:993254260935:web:42daa6b06a79cfd53afee2",
  measurementId: "G-R25QN3RMS8",
};

function HomePage() {
  const { id } = useParams();
  const [video, setVideo] = useState({});
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [member, setMember] = useState();

  let firebaseApp;
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
  } else {
    firebaseApp = firebase.app(); // if already initialized, use that one
  }
  // const analytics = getAnalytics(app);
  const db = firebaseApp.firestore();

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      setLoading(true);
      const formData = new FormData(e.target);
      const email = formData.get("email");
      // const res = await fetch(`http://localhost:8000/v1/videos/${id}/source`, {
      //   method: "post",
      //   body: formData,
      // });
      // const json = await res.json();
      // setVideo({ ...video, ...json });
      // if (json.source) {
      // setVerified(true);
      // } else {
      // setMessage("You're not allowed to watch the video");
      // }
      // if (email.indexOf("novonordisk.com") === -1) {
      //   setMessage("You're not allowed to watch the video");
      //   const col = await db.collection("migraine").doc("wrong_entries").get();
      //   const wrong_entires = col.data();
      //   wrong_entires.emails = {
      //     ...wrong_entires.emails,
      //     [`${email}-${Date.now()}`]: {
      //       email,
      //       time: Date.now(),
      //     },
      //   };
      //   db.collection("migraine").doc("wrong_entries").set(wrong_entires);
      // } else {
      const emailDoc = await db.collection("migraine").doc(email).get();
      let emailData = emailDoc.data();
      if (!emailData) emailData = { entries: [] };
      emailData.entries = [...emailData.entries, Date.now()];
      db.collection("migraine").doc(email).set(emailData);
      setMember(email);
      setVerified(true);
      // }
      setLoading(false);
    } catch (error) {
      console.log("onSubmit Error", error);
    }
    return false;
  };

  useEffect(() => {
    const getVideo = async () => {
      try {
        // setLoading(true);
        // const res = await fetch(`http://localhost:8000/v1/videos/${id}`);
        // const json = await res.json();
        // setLoading(false);
        setVideo(data);
        setVerified(!data.private);
      } catch (error) {
        console.log("ERROR", error);
      }
    };
    getVideo();
  }, [id]);

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${verified ? video.background_image : video.poster
          })`,
      }}
    >
      <img className="logo" src={logo} alt="logo" />
      {(verified && <VideoComponent data={video} member={member} />) || (
        <div className="select-lang-page">
          {(!video.uri && loading && <CircularProgress />) || (
            <>
              <Helmet>
                <title>{video.title}</title>
              </Helmet>
              <div className="form">
                <h1 className="big-title">{video.title}</h1>
                <div className="language-selector">
                  {video.private && (
                    <form onSubmit={onSubmit}>
                      <InputLabel
                      >
                        To Watch The Video Please Enter Your Business Email
                      </InputLabel>
                      <FormControl fullWidth margin="normal">
                        <OutlinedInput
                          disabled={loading}
                          required
                          variant="outlined"
                          name="email"
                          type="email"
                          error={message !== ""}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton type="submit" disabled={loading}>
                                {(loading && (
                                  <CircularProgress
                                    color="secondary"
                                    size="1rem"
                                  />
                                )) || <ArrowForward />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>{message}</FormHelperText>
                      </FormControl>
                    </form>
                  )}
                  {/* <p>Choose Language</p>
              <Button href="/arabic">
                <img src={arIcon} alt="Arabic" />
              </Button>
              <Button href="/english">
                <img src={enIcon} alt="English" />
              </Button> */}
                </div>
              </div>

            </>
          )}
        </div>
      )}
    </div>
  );
}

export default HomePage;
