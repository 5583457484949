import React, { createRef, useEffect } from "react";
import smallVideo from "../assets/novo/videos/smallVideo.mp4";
import classes from './video-styles.module.css'

const SmallVideo = (props) => {
    const videoRef = createRef();

    const init = () => {
        let duration = 0;
        let lastInteractionTime = 0;
        if (videoRef && videoRef.current) {
            duration = videoRef.current.duration;
        }
        const x = videoRef.current;
        x.play()
        console.log(x);
        // let sections = data.interactions.map((interaction) => {
        //     let time = interaction.time || duration;
        //     interaction.duration = time - lastInteractionTime;
        //     lastInteractionTime = interaction.time;
        //     interaction.disabled = true;
        //     return interaction;
        // });
        // setVideoData({ ...videoData, duration, sections });
    };


    function timeChange() {
        if (videoRef?.current?.currentTime >= videoRef.current.duration) {
            props.onFinish()
        }
    }
    return (
        <div>
            <video
                style={{ zIndex: 4000 }}
                ref={videoRef}
                id="video2"
                onLoadedData={init}
                // poster={videoData.poster}
                onTimeUpdate={timeChange}
                // onPlaying={() => setPlaying(true)}
                // onPause={() => setPlaying(false)}
                // onVolumeChange={() =>
                //     setVideoData({ ...videoData, muted: videoRef.current.muted })
                // }
                playsInline
            >

                <source id="mp4" src={smallVideo} type="video/mp4" />
                <p>Your user agent does not support the HTML5 Video element.</p>

            </video>
            <div onClick={() => {
                // props.onClose()
                props.onFinish()
                // setClickedNext(false)
            }} className={classes.close}>X</div>
        </div>
    )
}
export default SmallVideo;